import React from 'react';
import ReactPlayer from 'react-player';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

function App() {
  return (
    <Router>
      <div>
        <Switch>
          <Route path="/uvrmp">
            <UVRMP />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

function Home() {
  return (
    <div style={{height: '100vh', textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
    WORK IN
      <img
        style={{height: '50%'}}
        src="studio.jpg"
        alt="studio"
      />
      PROGRESS
    </div>
  );
}

function UVRMP() {
  return (
    <div style={{marginTop: '10vh', marginBottom: '10vh', marginLeft: '20vw', marginRight: '20vw'}}>
      <h1>
      UVRMP Sample Work
      </h1>
      Sample 1
      <br/>
      <br/>
      In this sample, Benjamin Clevenger demonstrates a prototype virtual reality environment in which visual elements are in sync with music. In the distance can be seen an energy pulse beam emitting from a whirlwind of energy. The beam pulses with heavy musical beats and a gust of dust and wind is dispersed from the whirlwind simultaneously. Even these are only small examples of what will be developed. In order to create the true desired effect, a software framework for the choreography of 3d models will be developed. This will give the developers more interesting and finely tuned control over the objects in a scene.

This sample was developed with original models, animations, and textures created by Benjamin Clevenger, music composed and produced by Matthew Clevenger, and under the creative direction of Matthew Clevenger.
      <br/>
      <br/>
      <div style={{width: '100%', textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <ReactPlayer
        url='https://mattclevenger-video.s3.amazonaws.com/uvrmp_demo_1.mp4'
        playing
        controls
        width='100%'
        height='100%'/>
      </div>
      <br/>
      <br/>
      Sample 2
      <br/>
      <br/>
      These are excerpts from compositions (works in progress) which are intended to be included on Matthew Clevenger’s upcoming album and are candidates for accompanying virtual reality experiences.
      <br/>
      <br/>
      <div style={{width: '100%', textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <ReactPlayer
        url='https://mattclevenger-audio.s3.amazonaws.com/uvrmp_audio_sample_1.mp3'
        playing
        controls
        playing='false'
        width='100%'
        height='44px'/>
        </div>
        <br/>
      <div style={{width: '100%', textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <ReactPlayer
        url='https://mattclevenger-audio.s3.amazonaws.com/uvrmp_audio_sample_2.mp3'
        playing
        controls
        playing='false'
        width='100%'
        height='44px'/>
    </div>
    </div>

  );
}

export default App;
